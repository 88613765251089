import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import Header, { Item, Float, Foot, Slider } from '../components/main'
import { Helm } from '../components/header'
import { Modal, Row, Col, Alert, Button } from 'react-bootstrap'
import { cap } from '../params'
import '../style/style.css'
import '../style/sty.scss'
import logoig from '../assets/img/nasta/logoig.svg'
import burung from '../assets/img/nasta/burung.svg'
import bunga6 from '../assets/img/bunga6.png'
import AOS from 'aos';
import { gambar, pw } from '../params'
import { Timer } from '../components/timer'
import post from '../params/post'
import getData from '../params/getdata'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import logo from '../assets/img/logo.ico'
import moment from 'moment'
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';
var x
if (typeof window !== `undefined`){
    x = window.matchMedia("(max-width: 900px)").matches
  }


let cmain = '#C0573B'
let black = 'rgb(38,38,38)'
let purple = '#820f44'
let id = 'azzahra-vido'
let inisial_co = 'Vido'
let inisial_ce = 'azzahra'

let lengkap_co = (<>IPDA Vido Wisnu Ramadhan, S.T., M.Han </>)
let lengkap_ce = (<>Azzahra Retnaning Basuki, S.Psi., M.Han </>)

let bapak_co = 'Bpk H. Edy Sunaryo'
let ibu_co = 'Ibu Mariyani Ulfah, S.E.'
let bapak_ce = "Bpk H. R. Basuki Adi Utomo, S.E."
let ibu_ce = "Ibu Hj. Firda Yanuar Kadi, S.H."

let ig_co = "vidowisnu"
let ig_ce = "azzahrarb"

let foto_ce = pw(id, "ce.jpg")
let foto_co = pw(id, "co.jpg")
let waktunikah = "01/16/2021"

let modal = pw(id, "modal.jpg")
let openlogo = pw(id, "logo.png")

let gmaps = "https://maps.app.goo.gl/N3Cne96FRkSN5wMFA"
let gcalendar = 'https://calendar.google.com/event?action=TEMPLATE&tmeid=MHVzMTZka3VlaGJmcXBtYjQxY2xkcXVsNXAgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com'
let gmaps1 = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3953.661085105068!2d114.00215231477767!3d-7.719464994435708!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0!2zN8KwNDMnMTAuMSJTIDExNMKwMDAnMTUuNiJF!5e0!3m2!1sid!2sid!4v1607223099284!5m2!1sid!2sid"



export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.myRef = React.createRef()
        this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()

        this.state = {
            acara: [],
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
            hadir: true,
            err: [],
            submitted: '',
            sesi: 0,
            lgShow: false,
            comment:[]
        }
    }

    async componentDidMount() {
        try {
            AOS.init({
                // initialise with other settings
                duration: 2000
            });
            let comment = await getData('kepada:"azzahra-vido"')
            

            this.setState({ comment: comment.reverse() })
            console.log(comment)
        } catch (error) {
            console.log(error)
        }

    }

    play = () => {
        AOS.refresh()
        var snd = new Audio(pw(id,"music.mp3"));
        snd.type = 'audio/mp3';
        snd.play();
        this.setState({ hide: false })
        setTimeout(() => {
            var elmnt = document.getElementById('top');
            elmnt.scrollIntoView();
        }, 1000)
    }

    useQuery = () => {
        return new URLSearchParams(this.props.location.search);
    }
    handleSubmit = async () => {
        let err = []
        let local = localStorage.getItem('pesan')
        if (this.nama.current.value == "") {
            err.push('Nama tidak Boleh Kosong')
        }
        if (this.pesan.current.value == "") {
            err.push('Pesan tidak Boleh Kosong')
        }
        if (err.length == 0) {
            confirmAlert({
                message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let send = await post(
                                ` dari: "${encodeURI(this.nama.current.value)}", hadir: "", jumlahhadir: "${new Date()}", kepada: "${id}", pesan:"${encodeURI(this.pesan.current.value)}",alamat: ""`
                            )
                            if (send.status == 200) {
                                this.setState({ submitted: true })
                                localStorage.setItem('pesan', this.pesan.current.value)
                                this.nama.current.value = ''
                                this.pesan.current.value = ''
                            } else {
                                err.push('Koneksi Gagal')
                            }


                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        } else {
            this.setState({ err: err, submitted: false })
        }
    }
    render() {
        let { hadir, days, hours, comment, lgShow, hide, submitted, err, sesi } = this.state
        let slider = []
        let slide = [
            "1.jpg",
            "10.JPG",
            "11.jpg",
            "12.JPG",
            "2.JPG",
            "3.JPG",
            "4.jpg",
            "5.JPG",
            "6.JPG",
            "7.jpg",
            "8.JPG",
            "9.jpg",
        ]
        slide.map(v => {
            slider.push(pw(id, v))
        })
        let query = this.useQuery().get('u');

        query = query ? cap(query) : ''

        return (
            <>
                <Helm
                    title={`Undanganku - ${inisial_ce} & ${inisial_co}`}
                    desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
                    logo={logo}
                    img={slider[0]}
                    url={`https://undanganku.me/${id}`}

                >
                    <link rel="preconnect" href="https://fonts.gstatic.com" />
                    <link href="https://fonts.googleapis.com/css2?family=Merienda:wght@400;700&display=swap" rel="stylesheet" />
                    <link href="https://fonts.googleapis.com/css2?family=PT+Sans+Narrow:wght@400;700&display=swap" rel="stylesheet" />
                </Helm>

                <div id='gold5' style={{
                    backgroundImage: `url(${'https://i.ibb.co/gmz96rb/Background-Putih.jpg'})`,
                    backgroundSize: 'cover', backgroundPosition: 'center'
                }}>
                    {
                        this.useQuery().get('x') == "x" ? (<Float />) : false
                    }
                    <div className='w-100' style={{
                        overflow: 'hidden', maxWidth: '100vw',
                        backgroundColor: 'transparent'
                    }}>
                        <Container fluid id='g3-header' className='relative' style={{
                            backgroundImage: `url('${modal}')`
                        }}>
                            <Item>
                                <Col xs={12} md={4} className='m-2 m-md-0 '>
                                    <img className='img-fluid w-100 p-2'
                                        src={gambar(openlogo)} data-aos="fade-left" />
                                </Col>
                            </Item>
                            <Item>
                                {
                                    <h2 className={`col-md-4 roboto-slab text-center pt-3 pt-sm-3`} style={{ marginTop: '0' }}>
                                       </h2>
                                }</Item>
                            <Row className='justify-content-center'>
                                <div onClick={() => { this.play() }}

                                    className={`col-md-4 button btn roboto-slab text-center ${hide ? 'show' : 'hide'}`}
                                    style={{ marginTop: 0, color: 'white' }}>
                                    Open Invitation
                            </div>
                            </Row>
                        </Container>

                        <div className={hide ? 'd-none' : 'd-block'}>
                            <div id="top" style={{ backgroundColor: 'transparent' }}>
                                <Container fluid style={{ backgroundImage:`url('${pw(id,"bg.jfif")}')`,backgroundSize:'100% ' }} className="py-3">
                                    <Container>
                                        <Item>
                                            <h2 data-aos="fade-down" style={{ color: 'white' }}> بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</h2>
                                        </Item>
                                        <p className="text-center py-3" style={{ color: 'white', fontSize: '18px' }} data-aos="fade-up">
                                            Assalamu'alaikum Warahmatullahi Wabarakatu
                                            Dengan memohon rahmat serta ridho dari Allah
                                            Subhanahu WaTa'ala.Bersama ini izinkan kami
                                            memohon doa restu bapak/ibu, saudara/i sekalian.<br />
                                            Insya Allah kami akan menikahkan Putri kami:
                                        </p>


                                        <Item>
                                            <div className="p-3">
                                            <table class="table table-borderless" >

<tbody>
    <tr className="row">
        <td className="col-12 col-md-5 align-middle p-2"
        data-aos="fade-up"
        style={{backgroundColor:purple,
        borderRadius:'10px'}}>
            <Item>
                <Col xs={6} md={6}>
                    <img src={pw(id, "ce.png")} className="w-100 img-fluid p-3 " />
                </Col>
            </Item>
            <Item>
                <p className="text-center"
                    style={{
                        fontFamily: 'PT Sans Narrow, sans-serif',
                        fontSize: '18px', color: 'white',
                        fontWeight: 700
                    }}>
                    {lengkap_ce}
                </p>
            </Item>
            <Item>
                <p className="text-center" style={{ fontSize: '14px', color: 'white' }}>
                    <b>Putri kedua dari:<br /></b>
                    {bapak_ce}&<br />
                    {ibu_ce}
                </p>
            </Item>
            <Item>
                <div onClick={() => {
                    window.location.href = `https://instagram.com/${ig_ce}`
                }} className="btn p-0">
                    <Item>
                        <img src={pw("asset", "logoig-white.svg")}
                            style={{ width: '25px', height: '25px' }} />
                        <p style={{ fontSize: '16px', color: 'white' }} className="px-2">@{ig_ce}</p>
                    </Item>
                </div>
            </Item>
        </td>
        <td className="col-12 col-md-2 text-center align-middle p-0"
            style={{ fontFamily: 'PT Sans Narrow, sans-serif', 
            fontSize: '24px', color: 'white' }}
            data-aos="fade-up">
          Dengan
        </td>
        <td className="col-12 col-md-5 align-middle p-0"
        style={{backgroundColor:purple,borderRadius:'10px'}}
        data-aos="fade-up">
            <Item>
                <Col xs={6} md={6}>
                    <img src={pw(id, "co.png")} className="w-100 img-fluid p-3 " />
                </Col>
            </Item>
            <Item>
                <p className="text-center" style={{
                    fontWeight: 700,
                    fontFamily: 'PT Sans Narrow, sans-serif',
                    fontSize: '18px', color: 'white'
                }}>
                    {lengkap_co}
                </p>
            </Item>
            <Item>
                <p className="text-center" style={{ fontSize: '14px', color: 'white' }}>
                    <b>Putra bungsu dari:<br /></b>
                    {bapak_co}&<br />
                    {ibu_co}
                </p>
            </Item>
            <Item>
                <div onClick={() => {
                    window.location.href = `https://instagram.com/${ig_co}`
                }} className="btn p-0">
                    <Item>
                        <img src={pw("asset", "logoig-white.svg")}
                            style={{ width: '25px', height: '25px' }} />
                        <p style={{ fontSize: '16px', color: 'white' }} className="px-2">@{ig_co}</p>
                    </Item>
                </div>
            </Item>
        </td>

    </tr>

</tbody>
</table>

                                            </div>
                                           </Item>
                                    </Container>

                                </Container>
                                <Container fluid style={{ backgroundColor: '#9e462f', color: 'white' }} className="py-3">
                                    <Container>
                                        <Item>
                                            <table class="table table-borderless" data-aos="fade-up">
                                                <tbody>
                                                    <tr>
                                                        <td style={{
                                                            color: 'white', fontSize: '64px',
                                                            fontWeight: 700
                                                        }} className="align-middle text-right"
                                                            data-aos="fade-right">
                                                            16
                                                        </td>
                                                        <td style={{ color: 'white', fontSize: '20px' }} className="align-middle"
                                                            data-aos="fade-left">
                                                            January <br />
                                                            <span style={{ fontSize: '28px' }}>2021</span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Item>
                                        <p className="text-center w-100 mb-0" data-aos="fade-up"
                                            style={{
                                                color: 'white', fontSize: '28px', fontWeight: 700,
                                                fontFamily: 'PT Sans Narrow, sans-serif'
                                            }}>
                                            <b>Akad & Reception</b> <br />
                                        </p>
                                        <p className="w-100 text-center" style={{ color: 'white' }} data-aos="fade-up">
                                            08.00 - Selesai
                                            
                                        </p>
                                        <p className="text-center py-2"
                                                                    style={{
                                                                        fontFamily: 'PT Sans Narrow, sans-serif',
                                                                        fontSize: '28px', color: 'white',
                                                                        fontWeight: 700
                                                                    }}>
                                                                    Live Streaming
                                                                </p>
                                        <p className="text-center w-100 py-3"
                                            style={{
                                                color: 'white', fontSize: '18px',
                                            }} data-aos="fade-up">
                                            Mengingat kondisi pandemi COVID-19 yang
                                            serba terbatas dan beresiko, kami mohon
                                            maaf tidak dapat mengundang Bapak, Ibu,
                                            saudara/i sekalian.<br />
                                            Penting bagi kami untuk menjaga semuanya
                                            tetap aman.
                                        </p>
                                        <p className="text-center w-100 py-3"
                                            style={{
                                                color: 'white', fontSize: '18px',
                                                fontStyle: 'italic'
                                            }} data-aos="fade-up">
                                            By asking for mercy and blessings from Allah
                                            Subhanahu WaTa'ala.<br />
                                            Given the limited and risky conditions of the
                                            COVID-19 pandemic, we apologize for not being
                                            able to invite you, ladies and gentlemen.<br />
                                            It's important for us to keep everything safe.
                                        </p>
                                        
                                    </Container>


                                </Container>
                                <Container fluid style={{backgroundColor:'#B54E4F',color:'white'}}>
                                        <Container>
                                        <p className="text-center w-100 py-3"
                                            style={{
                                                color: 'white', fontSize: '18px',
                                            }} data-aos="fade-up"
                                        >
                                            Namun demikian, kami berharap Bapak, Ibu, Saudara/i sekalian
                                            dapat mengikuti acara dimaksud melalui <span style={{fontStyle: "italic"}}>live streaming</span> berikut :
                                            <br/>
                                            <br/>
                                            <span style={{fontStyle: "italic"}}>
                                            <b onClick={() => {
                                                    window.location.href = "http://bit.ly/duayangbersatu"
                                                }}>
                                            Click here to see live streaming
                                            </b>
                                            </span>
                                        </p>
                                        <Item>
                                            <Button style={{ borderRadius: '10px',backgroundColor:'white' }} className="p-3 col-5 col-md-2" >
                                                <img onClick={() => {
                                                    window.location.href = "http://bit.ly/duayangbersatu"
                                                }} src={'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/Logo_of_YouTube_%282015-2017%29.svg/800px-Logo_of_YouTube_%282015-2017%29.svg.png'} className="w-100 img-fluid btn p-0" />

                                            </Button>
                                        </Item>
                                        
                                        <Item>
                                            <p className="text-center w-100 py-3"
                                                style={{
                                                    color: 'white', fontSize: '24px',
                                                    fontFamily: 'Tinos,serif'
                                                }} data-aos="zoom-in">
                                                DUA YANG BERSATU
                                              </p>
                                        </Item>
                                        </Container>
                                </Container>
                                <Container fluid style={{ backgroundImage:`url('${pw(id,"bg.jfif")}')`,
                                color:'white',
                                backgroundSize:'100%'}}>
                                    <Container className="py-3">
                                        <p className="w-100 text-center"
                                            style={{ fontFamily: 'Merienda, cursive', 
                                            fontWeight: 700, fontSize: '36px' }}>
                                            Our Story
                                        </p>
                                        {
                                            [
                                                {
                                                    gambar: <><Slider slide={[pw(id,"story1.jfif"),pw(id,"story11.jfif")]} /></>,
                                                    judul: 'Awal Mula',
                                                    content: (<>Berawal dari ketika Vido mengajak Zahra berkenalan ditahun 2018 dalam pendidikan dasar Bela Negara pasca sarjana di Rindam Jaya. Saat itu Vido melihat Zahra seperti melihat masa depannya. Beberapa momen Ketika Vido mencoba mendekati Zahra, selalu ada percikan cinta yang dirasakan Vido. Salah satunya saat latihan fisik dan bela diri yang mereka laksanakan setiap harinya, dimana hari sangat panas dan memang dilatih agar tidak lemah dalam kondisi apapun. Ketika semua peserta didik kepanasan termasuk Vido, namun saat Vido melihat Zahra disitulah rasa panas maupun lelah seakan hilang tiba-tiba, dan masih banyak percikan-percikan cinta di momen lain yang dirasakannya sampai akhirnya Zahra melihat Vido dengan tatapan dan perasaan yang sama. Sehingga diantaranya memilih jalan untuk berhubungan atas dasar kasih cinta.</>)
                                                },
                                                {
                                                    gambar: <><Slider slide={[pw(id,"11.jpg"),pw(id,"story22.jfif")]} /></>,
                                                    judul: 'Dua yang Bersatu',
                                                    content: (
                                                        <>
Tanggal lamaran dengan pernikahan<br/>
15 02 2020<br/>
16 01 2021<br/>
————————<br/>
1  -  1  -  1<br/>
Selisih satu<br/>
<br/>
Tanggal lahir Azzahra & Vido<br/>
Selisih 2 tahun, kurang 2 bulan, kurang 2 hari.<br/>
<br/>
Tanggal dari pertama bareng sampe nikah<br/>
14/11/2018 - 16/01/2021<br/>
Sudah 2 tahun, 2 bulan, 2 hari<br/>
<br/>
Ada apa dengan angka 1 & 2 ?<br/>
<br/>
Ketika satu adalah Allah<br/>
Dan dua adalah hamba hambanya.<br/>
Adam yang tadinya seorang dilengkapi dengan hawa kemudian dari satu menjadi dua insan.<br/>
Allah yang mempersatukan.<br/>
                                                        </>
                                                    )
                                                }
                                            ].map((v, i) => {
                                                return (
                                                    <Row className="py-2">
                                                        <Col md={6} xs={12} data-aos="fade-right" className={`px-2 order-md-${i % 2 ? 2 : 1}`}>
                                                            {v.gambar}
                                                        </Col>
                                                        <Col md={6} xs={12}
                                                            data-aos="fade-right" className={`px-2 order-md-${i % 2 ? 1 : 2}`}
                                                            style={{ color: 'white' }}>
                                                            <h1 className="w-100 text-center" 
                                                            style={{ fontFamily: 'PT Sans Narrow, sans-serif',fontSize:'20px' }}>
                                                                {v.judul}
                                                            </h1>
                                                            <p style={{ fontSize: '14px' }} className="text-center">
                                                                {v.content}
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                )
                                            })
                                        }

                                    </Container>
                                </Container>
                                <Container fluid id='g3-header' className='position-relative p-0 wahai' style={{
                                    Height: '75vh'
                                }}>
                                    <div style={{
                                        backgroundColor:'rgba(0,0,0,0.5)',
                                        color:'white',
                                        bottom:'10%'
                                    }}
                                    className="w-100 position-absolute">
                                        <p className="w-100 text-center" style={{fontSize:'14px'}} data-aos="fade-left" data-aos-duration="1000">
                                        “Wahai manusia! Bertakwalah kepada Tuhanmu yang telah menciptakan kamu dari diri yang satu (Adam), dan (Allah) menciptakan pasangannya (Hawa) dari (diri)-nya; dan dari keduanya Allah memperkembangbiakkan laki-laki dan perempuan yang banyak. Bertakwalah kepada Allah yang dengan nama-Nya kamu saling meminta, dan (peliharalah) hubungan kekeluargaan. Sesungguhnya Allah selalu menjaga dan mengawasimu.”<br/> (Qs. An-Nisa Ayat ke 1)
                                        </p>
                                    </div>
                                </Container>

                                <Container fluid id='g3-header' className='relative p-0 save' style={{
                                    height: '75vh'
                                }}>
                                    <p className="w-100 text-center"
                                        style={{ fontFamily: 'Merienda, cursive', 
                                        fontWeight: 700, fontSize: '36px' }}>
                                        16 January 2021
                                        </p>
                                    <Item>
                                        <Col xs={6} md={3}>
                                            <img src={pw(id, "save-the-date.gif")}
                                                className="w-100 img-fluid" />
                                        </Col>
                                    </Item>
                                    <Timer cmain={'transparent'} waktunikah={waktunikah} />

                                </Container>
                                <Container fluid style={{ backgroundColor: purple, color: 'white' }} className="py-3">
                                    <Item>
                                    <p className="text-center mx-5 py-3" style={{fontSize:'16px'}}>
                                    Tanpa mengurangi rasa hormat, bagi anda yang ingin memberikan tanda kasih untuk pengantin, dapat melalui:
                                    </p>
                                    </Item>
                                    <Item>
                                        <div className="p-3 px-5" style={{backgroundColor:'#E3A2C0'}}>
                                            <Item>
                                            <p className="text-center" style={{fontSize:'16px'}}>
                                                <b>5725607986</b><br/>
                                                Bank BCA<br/>
                                                a.n. Azzahra Retnaning Basuki
                                            </p>
                                            </Item>
                                            <Item>
                                            <CopyToClipboard text="5725607986">
                                                    <div className="position-relative">
                                                    
                                                    <Button style={{backgroundColor:'#9D2A2D',borderColor:'#A2205C'}} onClick={()=>{
                                                        this.setState({copy:true})
                                                    }}>
                                                        <Item>
                                                            <p className="px-2 mb-0" style={{fontSize:'14px'}}>
                                                            {this.state.copy?'Berhasil di Copy ke Clipboard':'Copy Nomor Rekening'} <img src='https://www.flaticon.com/svg/static/icons/svg/926/926768.svg' style={{width:'25px',height:'25px',marginLeft:'10px'}}/>
                                                        
                                                            </p>
                                                            </Item>
                                                    </Button>
                                                    <Button onClick={()=>{
                                                        this.setState({copy:true})
                                                    }}
                                                    style={{backgroundColor:'#CC6262',borderColor:'#A2205C',position:'absolute',left:'-5px',top:'-5px'}}
                                                >
                                                        <Item>
                                                            <p className="px-2 mb-0" style={{fontSize:'14px'}}>
                                                            {this.state.copy?'Berhasil di Copy ke Clipboard':'Copy Nomor Rekening'} <img src='https://www.flaticon.com/svg/static/icons/svg/926/926768.svg' style={{width:'25px',height:'25px',marginLeft:'10px'}}/>
                                                            </p>
                                                            </Item>
                                                    </Button>
                                                   
                                                    </div>
                                                    </CopyToClipboard>
                                                </Item>

                                            </div>
                                    </Item>

                                    </Container>
                                <Container fluid style={{backgroundColor:'#9E462F'}}>
                                    <Container>

                                        <p className="w-100 text-center py-3"
                                            style={{
                                                fontFamily: 'Merienda, cursive',
                                                fontWeight: 700, fontSize: '36px',
                                                fontStyle: 'italic',
                                                color:'white'
                                            }}>
                                            Video Lamaran Kami
                                        </p>
                                        <div className="embed-responsive embed-responsive-4by3 mb-3 p-3">
                                            <iframe className="embed-responsive-item"
                                                src="https://www.youtube.com/embed/uOVjoUtAtF0"></iframe>
                                        </div>
                                    </Container>
                                    <Container id='sectiongold58' >

                                        <div className='pt-3 mt-4 mt-lg-5 mb-lg-3'>
                                            <Item>
                                                <Col xs={4} lg={2}>
                                                    <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                                                </Col>
                                            </Item>
                                            <Item>
                                                <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                                                    <Item>
                                                        <h1 className="w-100 text-center" style={{
                                                            fontFamily: '"Marck Script", cursive',
                                                            color: cmain
                                                        }}>
                                                            Doa untuk Kedua Mempelai
                                                        </h1>
                                                    </Item>
                                                    <Item>
                                                        <form className="col-12 w-100">
                                                            <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Name" name='nama' />
                                                            <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Message" name='pesan' />
                                                            {
                                                                sesi == 1 || sesi == 2 ? (
                                                                    <Item>
                                                                        <div id="formradio">
                                                                            <div class="custom_radio row justify-content-center">
                                                                                <div onClick={() => {
                                                                                    this.setState({ hadir: true })
                                                                                }
                                                                                }>
                                                                                    <input type="radio" id="featured-1" name="featured" checked={hadir ? true : false} />
                                                                                    <label for="featured-1">Hadir</label>
                                                                                </div>
                                                                                <div onClick={() => {
                                                                                    this.setState({ hadir: false })
                                                                                }
                                                                                } className="pl-5">
                                                                                    <input type="radio" id="featured-2" name="featured" checked={hadir ? false : true} />
                                                                                    <label for="featured-2"

                                                                                    >Tidak Hadir</label>
                                                                                </div>
                                                                                {!hadir ? false : (
                                                                                    <>  <Alert variant='dark col-12 mr-4 '>

                                                                                        {
                                                                                            sesi == 1 ? (<div onClick={() => {
                                                                                                this.setState({ sesi: 1 })
                                                                                            }
                                                                                            }
                                                                                                className="pl-5">
                                                                                                <input type="radio" id="featured-3" name="sesi" checked={sesi == 1 ? true : false} />
                                                                                                <label for="featured-3">

                                                                                                    18.30 - 19.30 WIB
                                                                                    </label>
                                                                                            </div>) : (
                                                                                                    <div onClick={() => {
                                                                                                        this.setState({ sesi: 2 })
                                                                                                    }
                                                                                                    } className="pl-5">
                                                                                                        <input type="radio" id="featured-4" name="sesi" checked={sesi == 2 ? true : false} />
                                                                                                        <label for="featured-4"

                                                                                                        >19.30 - 20.30 WIB</label>
                                                                                                    </div>
                                                                                                )
                                                                                        }
                                                                                    </Alert>



                                                                                    </>

                                                                                )}

                                                                            </div>
                                                                        </div>
                                                                    </Item>


                                                                ) : false
                                                            }
                                                            <Item>
                                                                <Col xs={12} className=''>
                                                                    {
                                                                        submitted == true ? (
                                                                            <Alert variant='success' style={{ fontSize: '16px' }}>
                                                                                Pesan anda sudah disampaikan
                                                                            </Alert>) : (submitted === false ? (
                                                                                <Alert variant='danger' style={{ fontSize: '16px' }}>
                                                                                    {
                                                                                        err.map(val => {
                                                                                            return (
                                                                                                <li>{val}</li>
                                                                                            )
                                                                                        })
                                                                                    }

                                                                                </Alert>
                                                                            ) : false)
                                                                    }

                                                                </Col>
                                                            </Item>
                                                            <Item>
                                                                <div className='col-6 button rounded btn'
                                                                    onClick={() => this.handleSubmit()} style={{ backgroundColor: cmain, color: 'white' }} no> Kirim </div>
                                                            </Item>
                                                        </form>
                                                    </Item>
                                                </div>
                                            </Item>
                                        </div>
                                    </Container>
                                    <Container>
                                        <Row style={{ maxHeight: '50vh', overflowY: 'scroll', width: '100%' }} className="scroller">
                                            {comment.map((v, i) => {
                                                console.log(v)
                                                return (
                                                    <Row className="w-100 text-center mx-auto">
                                                        <Col></Col>
                                                        <Col xs={12} md={6} className="py-2">
                                                            <p className="w-100 text-left my-auto" 
                                                            style={{ fontSize: '18px',color:'black' }}>
                                                                <b>{decodeURI(v.dari)}</b><br />
                                                                {decodeURI(v.pesan)}<br />
                                                                <span  style={{ fontSize: '14px'}}>
                                                                {moment(v.createdAt).startOf('hour').fromNow()}
                                                                </span>
                                                            </p>
                                                        </Col>
                                                        <Col></Col>
                                                    </Row>
                                                )
                                            })
                                            }
                                        </Row>
                                    </Container>
                                    <Container className="py-3">
                                        <Row>
                                            <Col md={6} xs={12} className="p-0 btn" onClick={() => {
                                                this.setState({ lgShow: true, img: gambar(slider[1], 95) })
                                            }}>
                                                <img src={gambar(slider[1], 95, )} className="w-100 img-fluid"   />
                                            </Col>
                                            <Col md={6} xs={12} className="p-0 btn" onClick={() => {
                                                this.setState({ lgShow: true, img: gambar(slider[0], 95) })
                                            }}>
                                                <img src={gambar(slider[0], 95, )} className="w-100 img-fluid"   />
                                            </Col>
                                            <Col md={6} xs={12} className="p-0 btn" onClick={() => {
                                                this.setState({ lgShow: true, img: gambar(slider[2], 95) })
                                            }}>
                                                <img src={gambar(slider[2], 95, )} className="w-100 img-fluid"   />
                                            </Col>
                                            <Col md={6} xs={12} className="p-0 btn" onClick={() => {
                                                this.setState({ lgShow: true, img: gambar(slider[7], 95) })
                                            }}>
                                                <img src={gambar(slider[7], 95, )} className="w-100 img-fluid"   />
                                            </Col>
                                            <Col md={6} xs={12} className="p-0 btn" onClick={() => {
                                                this.setState({ lgShow: true, img: gambar(slider[3], 95) })
                                            }}>
                                                <img src={gambar(slider[3], 95, )} className="w-100 img-fluid"   />
                                            </Col>
                                            <Col md={6} xs={12} className="p-0 btn" onClick={() => {
                                                this.setState({ lgShow: true, img: gambar(slider[4], 95) })
                                            }}>
                                                <img src={gambar(slider[4], 95, )} className="w-100 img-fluid"   />
                                            </Col>

                                            <Col md={6} xs={12} className="p-0 btn" onClick={() => {
                                                this.setState({ lgShow: true, img: gambar(slider[5], 95) })
                                            }}>
                                                <img src={gambar(slider[5], 95, )} className="w-100 img-fluid"   />
                                            </Col>
                                            <Col md={6} xs={12} className="p-0 btn" onClick={() => {
                                                this.setState({ lgShow: true, img: gambar(slider[6], 95) })
                                            }}>
                                                <img src={gambar(slider[6], 95, )} className="w-100 img-fluid"   />
                                            </Col>
                                            <Col md={6} xs={12} className="p-0 btn" onClick={() => {
                                                this.setState({ lgShow: true, img: gambar(slider[10], 95) })
                                            }}>
                                                <img src={gambar(slider[10], 95, )} className="w-100 img-fluid"   />
                                            </Col>

                                            <Col xs={6} className="p-0 btn" onClick={() => {
                                                this.setState({ lgShow: true, img: gambar(slider[7], 95) })
                                            }}>
                                                <img src={gambar(slider[7], 95, )} className="w-100 img-fluid"   />
                                            </Col>
                                            <Col xs={6} className="p-0 btn" onClick={() => {
                                                this.setState({ lgShow: true, img: gambar(slider[8], 95) })
                                            }}>
                                                <img src={gambar(slider[8], 95, )} className="w-100 img-fluid"   />
                                            </Col>

                                        </Row>
                                        <Modal
                                            size="lg"
                                            show={lgShow}
                                            onHide={() => this.setState({ lgShow: false })}
                                            aria-labelledby="example-modal-sizes-title-lg"
                                            className="position-fixed"
                                        >
                                            <Modal.Header closeButton>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <Item>
                                                    <img src={this.state.img} className="img-fluid" style={{width: '50vw' }} />
                                                </Item>
                                            </Modal.Body>
                                        </Modal>
                                    </Container>
                                
                                    

                                    
                                </Container>
                               <Container fluid style={{color:'white', backgroundColor:'#821543'}}>
                               <Container>
                                <Item>
                                    <p className='text-center pt-3' style={{fontSize:'14px'}}>
                                        Yang Bersyukur & Berbahagia:<br/>
                                        {bapak_ce} <br/>& {ibu_ce}<br/><br/>
                                        {bapak_co} <br/>& {ibu_co}<br/>

                                    </p>
                                </Item>
                                <Foot ig={pw("asset", "logoig-white.svg")} />
                                </Container>
                               </Container>



                            </div>
                        </div>
                    </div>
                </div>

            </>


        )
    }
}

